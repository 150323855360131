import React from 'react';
import ReactDOM from 'react-dom/client';
import {IntlProvider} from "react-intl";

import messages_en from "./lang/en-CA.json";
import messages_fr from "./lang/fr-CA.json";

import './index.css';
import App from './app';


const messages = {
    'en': messages_en,
    'fr': messages_fr
};

const language = "fr";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <IntlProvider locale={navigator.language} messages={messages[language]}>
            <App/>
        </IntlProvider>
    </React.StrictMode>
);
