import { useState, useEffect } from 'react';
import { useIntl, injectIntl, FormattedMessage, IntlProvider } from 'react-intl';

import '../../css/global.css';

import event_logo_fr from '../../img/Planete-Z_fr.png';
import event_logo_en from '../../img/Planete-Z_en.png';
import group_logo from '../../img/zone01-wro.png';

import messages_en from "../../lang/en-CA.json";
import messages_fr from "../../lang/fr-CA.json";
const Home = ( {intl} ) => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');

  const switchToEnglish = () => {
    setLocale('en');
  };

  const switchToFrench = () => {
    setLocale('fr');
  };

  const RegistrationButton = () => {
    const intl = useIntl();
    return <a href={intl.formatMessage({ id: 'App.URL.Registration' })}>
          <button>
            <FormattedMessage id="App.Button.Registration" />
          </button>
        </a>;
  };

  const TeamManagementButton = () => {
    const intl = useIntl();
    return <a href={intl.formatMessage({ id: 'App.URL.TeamManagement' })}>
      <button>
        <FormattedMessage id="App.Button.ManageMyTeams" />
      </button>
    </a>;
  };

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
      <IntlProvider locale={locale} messages={locale === 'fr' ? messages_fr : messages_en}>
        <div>
          <table>
            <tbody>
            <tr>
              <td>
                <div>
                  <div className="event_group_logo">
                    <img src={locale === 'fr' ? event_logo_fr : event_logo_en} alt="event logo" />
                  </div>
                  <div className="event_name">
                    <FormattedMessage id="App.event.name" /><br></br>
                  </div>
                </div>
              </td>
              <td>
                <div className="group_logo">
                  <img src={group_logo} alt="group logo" />
                </div>
                <div className="event_group_action">
                  <RegistrationButton/>
                </div>
                <div className="event_group_action">
                  <TeamManagementButton/>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot className="event_group_options_footer">
            <tr>
              <td colSpan="2">
                <div className="event_group_options">
                  <button onClick={switchToEnglish}>
                    <FormattedMessage id="App.Button.English" />
                  </button>
                  <button onClick={switchToFrench}>
                    <FormattedMessage id="App.Button.French" />
                  </button>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </IntlProvider>
  );
};

export default injectIntl(Home);
