import { BrowserRouter, Routes, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";

import Home from './pages/home/Home';

import messages_en from "./lang/en-CA.json"; // Import English messages
import messages_fr from "./lang/fr-CA.json"; // Import French messages


function App() {
    // Define the default locale and messages
    const locale = "fr";
    const messages = locale === "fr" ? messages_fr : messages_en;

    return (
        <IntlProvider locale={locale} messages={messages}>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                </Routes>
            </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
